import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useStaticQuery, graphql } from 'gatsby'
import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import './programme.css'

const GOOGLE_MAP_LINK =
  'https://www.google.fr/maps/search/H%C3%B4tels/@47.8744427,-1.5497068,11.62z/data=!4m8!2m7!3m6!1zSMO0dGVscw!2sLa+Demeure+du+Pont,+Le+Pont+Benet,+35320+La+Couy%C3%A8re!3s0x480f305f6c398581:0x7e43265e711bcbb3!4m2!1d-1.486185!2d47.873657'

function Programme() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "title_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      leaveLeft: file(relativePath: { eq: "program/leave_left.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      leaveRight: file(relativePath: { eq: "program/leave_right.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      draw01: file(relativePath: { eq: "program/program_draw_01.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      draw02: file(relativePath: { eq: "program/program_draw_02.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      draw03: file(relativePath: { eq: "program/program_draw_03.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      draw04: file(relativePath: { eq: "program/program_draw_04.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      separator: file(relativePath: { eq: "leaves_separator.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      separatorSmall: file(relativePath: { eq: "separator_small.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="program">
      <SEO title="Le programme" />
      <GatsbyImage
        image={data.lights.childImageSharp.gatsbyImageData}
        className="page-title-lights"
        alt="Dessin d'une guirlande de lumières"
      />
      <h1 className="page-title">
        <span className="first-line">Les</span>
        <br />
        <span className="middle-line">INFOS UTILES</span>
        <br />
        <span className="last-line">(et futiles... hihihi)</span>
      </h1>

      <div className="info-date-container">
        <GatsbyImage
          image={data.leaveLeft.childImageSharp.gatsbyImageData}
          alt="petite plante decorative"
        />
        <div className="info-date">
          <span className="first-line">Le</span> 06 AOUT 2022
          <br />
          <span className="second-line">à noter dans l&apos;agenda...</span>
        </div>
        <GatsbyImage
          image={data.leaveRight.childImageSharp.gatsbyImageData}
          alt="petite plante decorative"
        />
      </div>

      <div className="sections-container">
        <div className="program-section">
          <h2 className="page-subtitle">Déroulement de la journée</h2>
          <GatsbyImage
            image={data.separator.childImageSharp.gatsbyImageData}
            className="separator"
            alt="Couronne de fleur - séparateur"
          />

          <div className="info-program left">
            <div className="info-program-flex">
              <GatsbyImage
                image={data.draw01.childImageSharp.gatsbyImageData}
                className="info-program-draw "
                alt="Caro et Yohann se battent avec les drapeaux de la mairie"
              />
            </div>
            <div className="info-program-desc">Rencard avec le maire</div>
          </div>

          <div className="info-program right">
            <div className="info-program-flex">
              <GatsbyImage
                image={data.draw02.childImageSharp.gatsbyImageData}
                className="info-program-draw "
                alt="Caro et Yohann se battent avec les drapeaux de la mairie"
              />
              <div className="info-program-blob blob-02">
                <div className="info-program-blob-hour">
                  14<span>h</span>30
                </div>
                <br />
                La Demeure du pont
                <br />
                <span className="city">La Couyère</span>
              </div>
            </div>
            <div className="info-program-desc">Discours et reniflements</div>
          </div>

          <div className="info-program left">
            <div className="info-program-flex">
              <div className="info-program-blob blob-03">
                <div className="info-program-blob-hour">
                  18<span>h</span>00
                </div>
                <br />
                La Demeure du pont
                <br />
                <span className="city">La Couyère</span>
              </div>
              <GatsbyImage
                image={data.draw03.childImageSharp.gatsbyImageData}
                className="info-program-draw "
                alt="Caro et Yohann se battent avec les drapeaux de la mairie"
              />
            </div>
            <div className="info-program-desc">Un pour tous, tous pour vin</div>
          </div>

          <div className="info-program right">
            <div className="info-program-flex">
              <GatsbyImage
                image={data.draw04.childImageSharp.gatsbyImageData}
                className="info-program-draw "
                alt="Caro et Yohann se battent avec les drapeaux de la mairie"
              />
              <div className="info-program-blob blob-04">
                <div className="info-program-blob-hour">
                  20<span>h</span>30
                </div>
                <br />
                La Demeure du pont
                <br />
                <span className="city">La Couyère</span>
              </div>
            </div>
            <div className="info-program-desc">
              Ventre qui roule amasse une mousse...
              <br />
              Ou deux...
              <br />
              ...puis fiesta !
            </div>
          </div>
        </div>

        <div className="hotel-section">
          <GatsbyImage
            image={data.separatorSmall.childImageSharp.gatsbyImageData}
            className="separator-small"
            alt="Séparateur - Petite fleur"
          />

          <p className="paragraph">
            Ok, maintenant vous savez comment ça va se passer. Mais, vous vous
            posez sûrement une question...
          </p>
          <h2 className="page-subtitle" style={{ marginTop: '1em' }}>
            Où va-t-on dormir ?
          </h2>
          <p className="paragraph">
            La famille sera directement logée chez nous.
            <br />
            Mais comme les places sont limitées, pour les autres, voici une
            liste d&apos;hôtels/chambres d&apos;hôtes à proximité de la salle.
          </p>

          <a
            href={GOOGLE_MAP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            className="hotel-list-btn"
          >
            Voir la liste
          </a>

          <p className="paragraph post-scriptum">
            PS1 : Pour ceux qui comptent veiller jusqu&apos;au bout de la nuit,
            il est possible de dormir dans la salle.
            <br />
            <br />
            PS2 : Et sinon, pour les plus courageux, il y a toujours moyen de
            planter la tente, huhu ;)
          </p>

          <GatsbyImage
            image={data.separatorSmall.childImageSharp.gatsbyImageData}
            className="separator-small bottom"
            alt="Séparateur - Petite fleur"
          />
        </div>
      </div>
      <Footer />
    </MainLayout>
  )
}

export default Programme
